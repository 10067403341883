import DOMPurify from 'dompurify';
<template>
  <div>
    <div class="login-bg-image" />
    <div id="app">
      <header id="header" class="header">
        <nav class="navbar p-0 navbar-dark navbar-expand-lg">
          <a target="_self" href="#" class="navbar-brand mx-auto mx-md-0">
            <img :src="`/img/header/${brand === 'portablepower' ? 'bobcat' : brand}.png`" :alt="`${brand === 'portablepower' ? 'bobcat' : brand} Logo`" class="header__logo">
          </a>
          <div id="appcontext" class="hide text-white p-2 text-center w-100">
            <span>Logging into: </span>
            <span id="appcontext_label" class="font-weight-bold" />
            <img id="appcontext_img" src="" class="appcontext_img hide pl-4">
          </div>
        </nav>
      </header>
      <div class="main-area flex-grow-1 flex-shrink-0" :class="`main-area`" :style="{backgroundImage: `url('/img/background/${brand === 'portablepower' ? 'bobcat' : brand}.png')`}">
        <div id="okta-login-container" />
      </div>
      <footer id="footer" class="bg-gray flex-shrink-0">
        <div class="bg-gray-800 pt-4 container">
          <div class="row">
            <div class="col-12 col-md-4 text-center text-md-left">
              <img v-if="brand !== 'geith'" :src="`/img/footer/${brand === 'portablepower' ? 'bobcat' : brand}.png`" :alt="`${brand === 'portablepower' ? 'bobcat' : brand} slogan`" class="mt-3 mb-4">
            </div>
            <div class="my-2 text-center text-md-right col-md-8 col-12">
              <div class="d-block d-md-inline">
                <svg id="globe-icon"
                     aria-hidden="true"
                     focusable="false"
                     data-prefix="fas"
                     data-icon="globe-americas"
                     role="img"
                     xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 496 512"
                     class="globe-icon svg-inline--fa fa-globe-americas fa-w-16 text-white"
                >
                  <path data-v-9d043886="" fill="currentColor" d="M248 8C111.03 8 0 119.03 0 256s111.03 248 248 248 248-111.03 248-248S384.97 8 248 8zm82.29 357.6c-3.9 3.88-7.99 7.95-11.31 11.28-2.99 3-5.1 6.7-6.17 10.71-1.51 5.66-2.73 11.38-4.77 16.87l-17.39 46.85c-13.76 3-28 4.69-42.65 4.69v-27.38c1.69-12.62-7.64-36.26-22.63-51.25-6-6-9.37-14.14-9.37-22.63v-32.01c0-11.64-6.27-22.34-16.46-27.97-14.37-7.95-34.81-19.06-48.81-26.11-11.48-5.78-22.1-13.14-31.65-21.75l-.8-.72a114.792 114.792 0 0 1-18.06-20.74c-9.38-13.77-24.66-36.42-34.59-51.14 20.47-45.5 57.36-82.04 103.2-101.89l24.01 12.01C203.48 89.74 216 82.01 216 70.11v-11.3c7.99-1.29 16.12-2.11 24.39-2.42l28.3 28.3c6.25 6.25 6.25 16.38 0 22.63L264 112l-10.34 10.34c-3.12 3.12-3.12 8.19 0 11.31l4.69 4.69c3.12 3.12 3.12 8.19 0 11.31l-8 8a8.008 8.008 0 0 1-5.66 2.34h-8.99c-2.08 0-4.08.81-5.58 2.27l-9.92 9.65a8.008 8.008 0 0 0-1.58 9.31l15.59 31.19c2.66 5.32-1.21 11.58-7.15 11.58h-5.64c-1.93 0-3.79-.7-5.24-1.96l-9.28-8.06a16.017 16.017 0 0 0-15.55-3.1l-31.17 10.39a11.95 11.95 0 0 0-8.17 11.34c0 4.53 2.56 8.66 6.61 10.69l11.08 5.54c9.41 4.71 19.79 7.16 30.31 7.16s22.59 27.29 32 32h66.75c8.49 0 16.62 3.37 22.63 9.37l13.69 13.69a30.503 30.503 0 0 1 8.93 21.57 46.536 46.536 0 0 1-13.72 32.98zM417 274.25c-5.79-1.45-10.84-5-14.15-9.97l-17.98-26.97a23.97 23.97 0 0 1 0-26.62l19.59-29.38c2.32-3.47 5.5-6.29 9.24-8.15l12.98-6.49C440.2 193.59 448 223.87 448 256c0 8.67-.74 17.16-1.82 25.54L417 274.25z" class="" /></svg>
                <select id="dxpLanguagePicker" class="custom-select border-0" @change="setLanguageAndReload($event.target.value)" @focus="languageFocus(true)" @blur="languageFocus(false)">
                  <option v-for="lang in supportedLanguages" :key="lang" :value="lang" :selected="selectedLanguage.includes(lang)">{{ getLanguageDisplayName(lang) }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-white container-fluid">
          <div class="container">
            <div data-test-id="footer-disclaimer" class="row mt-3 py-4">
              <div class="text-center pl-0 pb-3 pb-md-0 col-md-2 col-12" :class="[brand === 'geith' && 'col-md-6', !displayLeftSideFooter && 'd-none']">
                <img v-if="['bobcat', 'portablepower'].includes(brand)" :src="'/img/footer/doosan.png'" alt="Doosan" class="footer-logo mw-100">
                <img v-else :src="`/img/footer/${brand}.png`" :alt="`${brand} slogan`" class="footer-logo mw-100">
              </div>

              <div data-test-id="footer-copy-text" class="text-black pl-2 col-12" :class="[brand === 'geith' ? 'col-md-6 text-center text-md-right' : 'col-md-10' , !displayLeftSideFooter && 'col-md-12']">
                <div v-if="['bobcat', 'portablepower'].includes(brand)" data-test-id="footer-copy-text" class="small text-black pl-2 col-md-10 col-12">
                  <span class="custom-footertext"><b>Bobcat</b> is a Doosan company. Doosan is a global leader in construction equipment, power and water solutions, engines, and engineering, proudly serving customers and communities for more than a century. Bobcat®, the Bobcat logo and the colors of the Bobcat machine are registered trademarks of Bobcat Company in the United States and various other countries.</span>
                  <span class="custom-footercopyright text-center text-md-left">©2024 Bobcat Company. All rights reserved.</span>
                </div>
                <div v-else class="text-center text-md-left">
                  <span>©{{ new Date().getFullYear() }} {{ brandCopyright }} All rights reserved.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
/* eslint no-undef: 0 */
import envConfig from '@/config'
import apiUrls from '@/api/apiUrls'
import {getUrlParam, isValidBrandName, isDevApplication} from '@/utils'
import {mapContentLanguageToOktaCustomLanguage, mapOktaCustomLanguageToContentLanguage, getLanguageDisplayName} from '@/i18n'
import {contentService} from '@/services/contentService'
import {fetchAvailableLanguages} from '@/services/publicContentService'

const brandToProfileAttributeMap = {
  bobcat: 'Bobcat',
  geith: 'Geith',
  portablepower: 'Portable Power',
  ryan: 'Ryan',
  steiner: 'Steiner'
}

export default {
  data () {
    return {
      oktaSignIn: undefined,
      config: OktaUtil.getSignInWidgetConfig(),
      emailRegex: /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      stateTokenUrlRegex: /^.+stateToken=(.+)$/,
      supportedLanguages: [],
      selectedLanguage: undefined,
      adEmailSuffix: envConfig.adEmailSuffix,
      region: '-',
      localCtx: undefined,
      applicationName: '',
      applicationId: '',
      applicationLogoHref: '',
      redirectUri: '',
      brand: envConfig.brand,
      enigmaId: ''
    }
  },
  computed: {
    displayLeftSideFooter() {
      return ['bobcat', 'geith', 'portablepower'].includes(this.brand)
    },
    brandCopyright() {
      switch(this.brand) {
      case 'steiner':
      case 'ryan': return 'Clark Equipment Co'
      case 'geith': return 'Geith International Ltd'
      default: return envConfig.brand
      }
    }
  },
  created() {
    this.detectAndSetupApplicationContext()
    this.detectAndSetupBrand()
  },
  mounted () {
    this.$nextTick(() => {
      this.init()
    })
  },
  destroyed () {
    if (this.oktaSignIn) this.oktaSignIn.remove()
  },
  methods: {
    getLanguageDisplayName,
    toEmailFormat(string, emailSuffix) {
      return this.emailRegex.test(string) ? string : string.replace(/\s/g, '').toLowerCase() + emailSuffix
    },
    transformUsername(username) {
      const result = this.toEmailFormat(username, this.adEmailSuffix)
      const userNameInput = document.getElementById('okta-signin-username')
      if(userNameInput) userNameInput.value = result
      return result
    },
    getUrlParameter(k) {
      const p = {}
      location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(s, k, v){p[k] = v})
      return k ? p[k] : p
    },
    getBrowserLocale() {
      return navigator.language.split('-')[0] || 'en'
    },
    getCustomTranslation(lang) {
      lang = lang || 'en'
      return fetch(apiUrls.CONTENT_TRANSLATIONS
        .replace('{path}', 'common').replace('{lang}', mapOktaCustomLanguageToContentLanguage(lang)))
        .then(function(response) {
          return response.json()
        }).then(function(json) {
          return json
        }).catch(function() {
          if(lang !== 'en')
            return this.getCustomTranslation()
          else
            return {}
        })
    },
    joinTranslationObjects(oktaObject, jsonObject) {
      const result = {}

      if(oktaObject)
        Object.keys(oktaObject).forEach(function(key) { result[key] = oktaObject[key] })

      Object.keys(jsonObject).forEach(
        function(key) {
          Object.keys(jsonObject[key]).forEach(
            function(subkey) {
              result[key.concat('.', subkey)] = jsonObject[key][subkey]
            }
          )
        }
      )
      return result
    },
    setLanguageAndReload(val) {
      if(this.supportedLanguages.indexOf(val) !== false){
        localStorage.setItem('dxpSelectedLanguage', val)
        location.reload()
      }
    },
    languageFocus(val) {
      const globe = document.getElementById('globe-icon')
      if(val) {
        globe.classList.remove('text-white')
        globe.classList.add('text-black')
      } else {
        globe.classList.add('text-white')
        globe.classList.remove('text-black')
      }
    },
    async initCustomTranslations() {
      const that = this
      await this.getCustomTranslation(this.selectedLanguage).then(function(response) {
        that.config.i18n[that.selectedLanguage] = that.joinTranslationObjects(that.config.i18n[that.selectedLanguage], response)
        that.customTranslate()
      }).catch(function() {
        //error when parsing
      })
    },
    customLabelReplace(className, label) {
      if(!label) return
      const items = document.getElementsByClassName(className)
      const item = items[0] || null
      if(!item) return
      // label is html
      if(/<[a-z/][\s\S]*>/i.test(label)) {
        item.innerHTML = label
        return
      }

      if(item.innerText !== '') item.innerText = label
      else if(item.value !== '') item.value = label
    },
    customTranslate() {
      if(!this.config.i18n[this.selectedLanguage]) return
      this.customLabelReplace('custom-toc', this.config.i18n[this.selectedLanguage]['registration.toc'] || null)
      this.customLabelReplace('custom-tocValidation', this.config.i18n[this.selectedLanguage]['registration.tocValidation'] || null)
      this.customLabelReplace('custom-continue', this.config.i18n[this.selectedLanguage]['common.continue'] || null)
      this.customLabelReplace('custom-role', this.config.i18n[this.selectedLanguage]['login.role'] || null)
      this.customLabelReplace('custom-bobcatdealer', this.config.i18n[this.selectedLanguage]['login.roleDealer'] || null)
      this.customLabelReplace('custom-bobcatemployee', this.config.i18n[this.selectedLanguage]['login.roleEmployee'] || null)
      this.customLabelReplace('custom-footertext', this.config.i18n[this.selectedLanguage]['footer.text'] || null, true)
      this.customLabelReplace('custom-footercopyright', this.config.i18n[this.selectedLanguage]['footer.copyright'] || null)
    },
    removeUnlockAndForgetPasswordButtons() {
      const forgotPassword = document.querySelector('[data-se=forgot-password]')
      const unlockAccount = document.querySelector('[data-se=unlock]')
      if (forgotPassword) {
        forgotPassword.parentNode.removeChild(forgotPassword)
      }
      if (unlockAccount) {
        unlockAccount.parentNode.removeChild(unlockAccount)
      }
    },
    addToc() {
      const that = this
      const endOfForm = document.querySelector('.o-form-button-bar, .required-fields-label')
      let tocDiv = document.createElement('div')
      tocDiv.classList.add('o-form-fieldset')
      tocDiv.classList.add('o-form-label-top')
      tocDiv.classList.add('margin-btm-0')
      tocDiv.innerHTML = '<div class="o-form-input" id="toc-custom-field" style="width:100%"><span class="o-form-input-name-remember"><div class="custom-checkbox"><input type="checkbox" id="toc" name="custom_toc"><label for="custom_toc" id="toc-custom-label" class="toc_label">Loading</label><p class="okta-form-input-error o-form-input-error o-form-explain" role="alert"><span class="icon icon-16 error-16-small" role="img" aria-label="Error"></span><span class="custom-tocValidation">You have to accept the Terms of Use &amp; Privacy Policy</span></p></div></span></div>'

      endOfForm.parentElement.insertBefore(tocDiv, endOfForm)
      const customTOC = document.getElementById('toc-custom-label')

      customTOC.addEventListener('click', function(event) {
        if (!event.target.classList.contains('toc_label')) return
        if (this.classList.contains('checked')) {
          this.classList.remove('checked')
        } else this.classList.add('checked')
      })

      document.getElementById('toc').addEventListener( 'change', function(event) {
        if (!event.target.classList.contains('toc_label')) return
        if(this.checked) {
          document.getElementsByClassName('toc_label')[0].classList.add('checked')
        } else {
          document.getElementsByClassName('toc_label')[0].classList.remove('checked')
        }
      })

      const backButton = document.querySelector('.link.js-back, .link.help')
      backButton.addEventListener('click', function() {
        that.region = '-'
      })
    },
    changeTocRegion(newRegion, brand) {
      if(newRegion && this.region !== newRegion) {
        this.region = newRegion
        this.fetchAndChangeTocText(newRegion, brand, this.getApplicationName())
      }
    },
    addErrorToTheComponent(parentComponent, errorMessage) {
      if (parentComponent.getElementsByClassName('o-form-input-error').length)
        return
      const errorParagraph = document.createElement('p')
      errorParagraph.innerHTML = `<span class="icon icon-16 error-16-small" role="img" aria-label="Error"></span><span>${errorMessage}</span>`
      errorParagraph.classList.add('okta-form-input-error')
      errorParagraph.classList.add('o-form-input-error')
      errorParagraph.classList.add('o-form-explain')
      errorParagraph.role = 'alert'
      parentComponent.appendChild(errorParagraph)
    },
    detectAndSetupApplicationContext() {
      let ctx = OktaUtil.getRequestContext()

      if (!(window.performance && performance.navigation.type === performance.navigation.TYPE_RELOAD)) {
        localStorage.removeItem('applicationContext')
      }

      if (window.performance && performance.navigation.type === performance.navigation.TYPE_RELOAD) {
        this.localCtx = JSON.parse(localStorage.getItem('applicationContext'))
        if (this.localCtx && this.localCtx.relayState) this.config.relayState = this.localCtx.relayState
        if (this.localCtx && this.localCtx.stateToken) this.config.stateToken = this.localCtx.stateToken
      }

      if(!ctx && !this.localCtx) return

      if(!ctx)
        ctx = this.localCtx
      else {
        ctx.relayState = this.config.relayState
        ctx.stateToken = this.config.stateToken
        localStorage.setItem('applicationContext', JSON.stringify(ctx))
      }

      if(ctx.target === undefined) return
      this.applicationName = ctx.target.label || ''
      this.applicationId = ctx.target.clientId

      if(ctx.target.links && ctx.target.links.logo) {
        this.applicationLogoHref = ctx.target.links.logo.href
      }

      this.redirectUri = ctx?.authentication?.request?.redirect_uri

      if(this.applicationName.indexOf('Bobcat Direct') !== -1 || this.applicationName.indexOf('Dealer Portal') !== -1 || this.applicationName.indexOf('Cloudinary') !== -1 || this.applicationName.indexOf('Parts Portal SPA') !== -1 || this.applicationName.indexOf('DMIQ Mobile App') !== -1 || this.applicationName.indexOf('FOD Mobile App') !== -1)
        this.config.features.registration = false
    },
    renderApplicationContext() {
      if(this.applicationName) {
        document.getElementById('appcontext_label').innerText = this.applicationName
      }
      if(this.applicationLogoHref) {
        const img = document.getElementById('appcontext_img')
        img.src = this.applicationLogoHref
      }
    },
    getApplicationName() {
      if(!this.applicationName) return ''
      let app = this.applicationName.toLowerCase()
      app = app.replaceAll(/\/\((dev|qa|prod)\)\/|\[(dev|qa|prod)\]/gm, '').trim()
      app = app.replaceAll(' ', '-')
      return app
    },
    getApplicationContext() {
      const applicationContextAsString = localStorage['applicationContext']
      return (applicationContextAsString !== undefined) ? JSON.parse(applicationContextAsString) : undefined
    },
    selectBrandDropdown() {
      return document.getElementsByName('userProfile.brand')[0]
    },
    setUpBrandField() {
      const brandContainer = document
        .getElementsByClassName('o-form-input-name-userProfile.brand')[0]
        .parentElement.parentElement
      brandContainer.style.display = 'none'

      const dropdown = this.selectBrandDropdown()
      const transformedBrand = brandToProfileAttributeMap[this.brand] || this.brand
      const optionToPreselect = Array.from(dropdown.options)
        .find(option => option.text === transformedBrand)
      optionToPreselect.selected = true

      dropdown.dispatchEvent(new Event('change', {bubbles: true}))
    },
    getBrandValue(){
      const dropdown = this.selectBrandDropdown()
      return dropdown.options[dropdown.selectedIndex].text
    },
    fetchAndChangeTocText(region, brand, application) {
      let xhr = new XMLHttpRequest()
      let url = `${apiUrls.CONTENT_API_ROOT}/public/toc?brand=${brand.toLowerCase()}&channel=owner&region=${region.toLowerCase()}&lang=${this.selectedLanguage}`
      if(application !== '') url += '&application=' + application
      xhr.open('GET', url, true)
      let tocText = '<span class="custom-toc">By clicking this box, I agree to</span> '
      xhr.onerror = function () {
        if(application !== '') this.fetchAndChangeTocText(region, brand, '')
      }
      const that = this
      xhr.onload = function () {
        if (xhr.status === 200) {
          const data = JSON.parse(xhr.response)
          if(!data.documents) return

          for (let i = 0 ; i < data.documents.length; i++) {
            if(i > 0) tocText += (i < data.documents.length - 1) ? ', ' : ' &  '
            let title = data.documents[i].title.en ? data.documents[i].title.en : data.documents[i].title

            let replaceRegex = new RegExp('-(' + that.selectedLanguage + '|en)$', 'g')
            let filename = data.documents[i].filename.replace(replaceRegex, '')

            tocText += '<a href="/app/toc/' + brand.toLowerCase() + '/' + region.toLowerCase() + '/owner/'
            if(application !== '') tocText += application + '/'
            tocText += filename + '" target="_blank">' + title + '</a>'
          }

          let tocLabel = document.getElementById('toc-custom-label')
          if(tocLabel) tocLabel.innerHTML = DOMPurify.sanitize(tocText)
          that.customTranslate()
        } else {
          if(application !== '') that.fetchAndChangeTocText(region, brand, '')
        }
      }
      xhr.send()
    },
    setupChznCountryVariable(userCountrySelect) {
      const userCountrySelectLabel = document.querySelector('label[for="' + userCountrySelect.id + '"]')
      userCountrySelectLabel.parentNode.parentNode.classList.add('customUserCountryCode')
      if(!window.userCountrySelectChznLabel)
        window.userCountrySelectChznLabel = document.querySelector('#' + userCountrySelect.id + '_chzn .chzn-single span')
    },
    changeCountry(country) {
      const userCountrySelect = document.getElementsByName('userProfile.userCountryCode')[0]
      this.setupChznCountryVariable(userCountrySelect)
      if(!window.userCountrySelectChznLabel) return

      window.userCountrySelectChznLabel.innerText = country.countryName

      let event
      if (typeof(Event) === 'function') {
        event = new Event('chosen:updated')
      } else {
        event = document.createEvent('Event')
        event.initEvent('chosen:updated', true, true)
      }

      userCountrySelect.dispatchEvent(event)
    },
    addIdAsNameParam(id) {
      const elem = document.getElementById(id)
      if(elem && !elem.name) elem.name = id
    },
    getCookie(k){return(document.cookie.match('(^|; )' + k + '=([^;]*)') || 0)[2]},
    async initConfig () {
      const paramLanguage = this.getUrlParameter('lang') || false
      const storageLanguage = localStorage.getItem('dxpSelectedLanguage') || false
      await fetchAvailableLanguages().then((languagesList) => {
        this.supportedLanguages = languagesList
        const langOkta = storageLanguage || paramLanguage || this.getBrowserLocale()
        if(this.supportedLanguages.indexOf(langOkta) === -1) {
          this.selectedLanguage = 'en'
        } else {
          this.selectedLanguage = mapContentLanguageToOktaCustomLanguage(langOkta)// nl -> nl-NL
        }
        this.config.language = this.selectedLanguage
        this.config.i18n[this.selectedLanguage] = {}
      }).catch((e) => {
        // eslint-disable-next-line no-console
        console.debug(e)
        this.selectedLanguage = 'en'
      })

      await this.initCustomTranslations()
      await this.renderApplicationContext()

      if(this.config.registration === undefined) this.config.registration = {}
      this.config.registration.preSubmit = function (postData, onSuccess) {
        const formField = document.getElementById('toc-custom-field')
        const label = document.getElementById('toc-custom-label')
        formField.classList.remove('o-form-has-errors')

        const sel = document
          .getElementsByName('userProfile.userCountryCode')[0]
        const countryCode = sel.options[sel.selectedIndex].getAttribute('data-code')

        if(countryCode && postData.userCountryCode !== countryCode) {
          postData.userCountryCode = countryCode
        }

        if(!label.classList.contains('checked')){
          formField.classList.add('o-form-has-errors')
        } else {
          onSuccess(postData)
        }
      }

      if(this.config.i18n === undefined) this.config.i18n = {}
      if(this.config.i18n.en === undefined) this.config.i18n['en'] = {}
      this.config.i18n['en']['primaryauth.submit'] = 'Login'
      if(this.brand === 'geith'){
        this.config.i18n['en']['primaryauth.username.placeholder'] = 'Username/Email Address'
      }


      this.config.transformUsername = this.transformUsername


      // translations end

      // end of custom config

      /*oktaSignIn.on('afterError', function (context, error) {
        console.log(context.controller)
        // reset-password

        console.log(error.name)
        // AuthApiError

        console.log(error.message)
        // The password does not meet the complexity requirements
        // of the current password policy.

        console.log(error.statusCode)
        // 403
      })*/
    },
    cleanupOktaTokenStorage() {
      // remove any hanging token storage
      localStorage.removeItem('okta-token-storage')
    },
    async init () {
      const that = this
      await this.initConfig()
      this.cleanupOktaTokenStorage()
      // Render the Okta Sign-In Widget
      this.oktaSignIn = new OktaSignIn(this.config)
      this.oktaSignIn.renderEl({el: '#okta-login-container'})

      this.oktaSignIn.on('afterRender', (context) => {
        const ctx = OktaUtil.getRequestContext()

        if (ctx) {
          document.cookie = 'dxpClientId=' + ctx.target.clientId + ';path=/'
          document.cookie = 'dxpClientLabel=' + ctx.target.label + ';path=/'
        } else {
          document.cookie = 'dxpClientId=;path=/'
          document.cookie = 'dxpClientLabel=;path=/'
        }

        if(context.controller === 'primary-auth') {
          if (!that.getCookie('cp')) {
            that.customTranslate()
          }
        }

        if(context.controller === 'registration' || context.formName === 'enroll-profile') {
          let xhr = new XMLHttpRequest()
          xhr.open('GET', `${apiUrls.CONTENT_API_ROOT}/public/locations`)
          xhr.send()

          const userApplicationRef = document.getElementsByName('userProfile.applicationRef')[0]
          userApplicationRef.parentNode.parentNode.parentNode.style.display = 'none'
          userApplicationRef.parentNode.parentNode.parentNode.classList.add('margin-btm-0')

          const userCountrySelect = document.getElementsByName('userProfile.userCountryCode')[0]
          document.querySelector('label[for="' + userCountrySelect.id + '"]').parentNode.parentNode.classList.add('customUserCountryCode')

          xhr.onload = function() {
            if (xhr.status === 200) {
              const data = JSON.parse(xhr.response)

              let countriesRegions = {}
              data.available.forEach(function(elem) {
                countriesRegions[elem.countryName] = {
                  region: elem.region,
                  code: elem.countryCode
                }
              })

              const options = userCountrySelect.querySelectorAll('option')
              for(let i = 0; i < options.length ; i++) {
                if(!countriesRegions[options[i].innerText]) continue
                options[i].setAttribute('data-region', countriesRegions[options[i].innerText].region)
                options[i].setAttribute('data-code', countriesRegions[options[i].innerText].code)
                if(options[i].innerText === data.detected.countryName) options[i].selected = true
              }

              that.changeCountry(data.detected)
              const brand = that.brand
              that.fetchAndChangeTocText(data.detected.region, brand, that.getApplicationName())

              const observer = new MutationObserver(function() {
                document.getElementsByClassName('toc_label')[0].classList.remove('checked')
                const newRegion = userCountrySelect.options[userCountrySelect.selectedIndex].getAttribute('data-region')
                that.changeTocRegion(newRegion, brand)
              })
              window.userCountrySelectChznLabel = document.querySelector('#' + userCountrySelect.id + '_chzn .chzn-single span')

              observer.observe(window.userCountrySelectChznLabel, {childList: true})
            }
            that.setUpBrandField()
            that.addToc()
          }
        }
        if(this.getApplicationName().indexOf('dmiq-mobile-app') !== -1) {
          this.removeUnlockAndForgetPasswordButtons()
        }
        this.customTranslate()
        this.addIdAsNameParam('form1')
        this.addIdAsNameParam('okta-signin-submit')
      })
    },
    async detectAndSetupBrand() {
      const queryParamBrand = getUrlParam('brand')
      if (queryParamBrand && isValidBrandName(queryParamBrand)) {
        this.brand = queryParamBrand
      }
      else if(window.location.host.includes('geith')) {
        this.brand = 'geith'
      }

      if (this.applicationId) {
        await this.fetchBrandData(this.applicationId, this.redirectUri)
      }

      this.applyBrandDesign()
    },
    async fetchBrandData(appId, redirectUri) {
      try {
        let brandInfo = isDevApplication(redirectUri) ? await contentService.fetchDevBrandInfo(appId) : await contentService.fetchBrandInfo(appId)
        brandInfo = JSON.parse(JSON.stringify(brandInfo))
        this.brand = brandInfo.data?.Brand?.toLowerCase() ?? envConfig.brand
        this.enigmaId = brandInfo.data?.EnigmaApplicationId ?? ''
      } catch (e) {
        this.brand = envConfig.brand
        this.enigmaId = ''
      }
    },
    applyBrandDesign() {
      if(!!this.brand && this.brand !== '') {
        document.querySelector('body')?.setAttribute('class', (this.brand === 'portablepower' ? 'bobcat' : this.brand))
      }
    },
    changeBrand(brand) {
      this.brand = brand
      this.applyBrandDesign()
    }
  }
}
</script>

<style lang="scss">
  #okta-login-container .button-secondary{
    border: 1px solid #ff3600 !important;
    border-radius: 2px !important;
    font-family: 'Helvetica Neue Bold Condensed';
    font-size: 1rem !important;
  }
  #okta-login-container .custom-checkbox  {
    width: 100% !important;
    line-height: 16px !important;
  }
  #okta-login-container .custom-checkbox label {
    background-position-y: 10px !important;
  }
  #okta-sign-in .custom-checkbox label.checked, #okta-sign-in .custom-radio label.checked {
    background-position: -16px -190px !important;
  }

  #dxpLanguagePicker {
    background: #333 url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5'%3E%3Cpath fill='%23fff' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right .75rem center/8px 10px;
    border: none;
    color: #fff;
    font-family: Helvetica Neue Bold Condensed;
    padding-left: 2rem;
    width: auto;
    min-width: 1rem;
  }
  #dxpLanguagePicker:focus {
    background: #FFF url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5'%3E%3Cpath fill='%23000' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right .75rem center/8px 10px;
    border: none;
    color: #000;
  }
  .globe-icon {
    width: 20px;
    height: 20px;
    position: relative;
    left: 1.75rem;
    pointer-events: none;
  }
  #okta-sign-in {
    margin: 1rem auto 1rem !important;

    .o-form-fieldset {
      margin-bottom: 0.2rem;
    }
  }
  #okta-sign-in .custom-checkbox label {
    height: unset !important;
  }
  .navbar-brand {
    padding: 0.875rem 0.5rem;

    .header__logo {
      max-height: 35px;
    }
  }

  @import "../../scss/login_brands";
</style>
